<template>
  <div>{{ localTime }}</div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AntUnixToLocalTime',
  props: {
    timestamp: {
      type: String,
      required: true,
    },
  },
  computed: {
    localTime() {
      return moment
        .unix(parseInt(this.timestamp))
        .format('YYYY-MM-DD HH:MM:ss');
    },
  },
};
</script>

<style scoped></style>
