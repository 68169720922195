<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card v-if="dhmeYardManagementSelectedTask">
      <v-card-title class="justify-center text-uppercase headline">
        Store {{ dhmeYardManagementSelectedTask.task_type.custom_1 }} '{{
          dhmeYardManagementSelectedTask.task_type.custom_2
        }}' in
        <v-icon class="mx-2">
          {{
            getHallOrLocation(dhmeYardManagementSelectedTask.task_type.custom_4)
              .hall
              ? 'mdi-warehouse'
              : 'mdi-file-table-box'
          }}
        </v-icon>
        {{
          getHallOrLocation(dhmeYardManagementSelectedTask.task_type.custom_4)
            .hall
            ? getHallOrLocation(
                dhmeYardManagementSelectedTask.task_type.custom_4
              ).hall
            : getHallOrLocation(
                dhmeYardManagementSelectedTask.task_type.custom_4
              ).name
        }}
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <v-form v-if="!isAssemblyHall" ref="store-element-form">
          <ant-input label="Level">
            <template #input-field>
              <v-select
                v-model="locationLevel"
                placeholder="Level"
                hide-details
                :items="locationLevels"
                dense
                filled
                single-line
                type="text"
              />
            </template>
          </ant-input>

          <storage-location-level-layout
            v-if="locationLevel"
            :location-level="locationLevel"
            :storage-location="storeLocation"
            :storage-boxes="dhmeYardManagementStorageBoxes"
            :selected-box="selectedStorageBox"
            :level-items="levelStoredItems"
            @selectStorageBox="selectStorageBox"
          />
        </v-form>
      </div>

      <v-card-actions class="ant-dialog-actions-bg ant-border-top">
        <v-spacer />
        <v-btn color="error" text small @click="closeDialog"> Cancel </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :disabled="!selectedStorageBox"
          @click="storeElementInLocation"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import StorageLocationLevelLayout from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/StorageLocationLevelLayout.vue';

export default {
  name: 'StoreElementDialog',
  components: { StorageLocationLevelLayout, AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      locationLevel: null,
      levelStoredItems: [],
      selectedStorageBox: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeYardManagementSelectedTask',
      'dhmeYardManagementStorageLocations',
      'dhmeYardManagementAssemblyHalls',
      'dhmeYardManagementStorageBoxes',
      'dhmeYardManagementModuleId',
    ]),
    showDialog() {
      return this.displayed;
    },

    isAssemblyHall() {
      return (
        this.dhmeYardManagementAssemblyHalls.findIndex(
          (item) =>
            item === this.dhmeYardManagementSelectedTask.task_type.custom_4
        ) >= 0
      );
    },
    storeLocation() {
      return this.dhmeYardManagementStorageLocations.find(
        (item) =>
          item.id === this.dhmeYardManagementSelectedTask.task_type.custom_4
      );
    },
    locationLevels() {
      return [
        ...new Set(
          this.dhmeYardManagementStorageBoxes
            .filter((item) => item.storage_location === this.storeLocation.id)
            .map((item) => item.level)
        ),
      ].sort((a, b) => a - b);
    },
  },
  watch: {
    locationLevel(value) {
      if (value) {
        this.selectedStorageBox = null;
        this.fetchLocationLevelElements();
      }
    },
  },
  methods: {
    selectStorageBox(item) {
      this.selectedStorageBox = item;
    },
    async fetchLocationLevelElements() {
      this.levelStoredItems = await executeCustomModuleCall(
        this.project.id,
        this.dhmeYardManagementModuleId,
        'fetchLocationLevelStoredElements',
        {
          location: this.storeLocation.id,
          level: this.locationLevel,
        }
      );
    },
    closeDialog() {
      this.$refs['store-element-form'].reset();
      this.locationLevel = null;
      this.levelStoredItems = [];
      this.selectedStorageBox = null;
      this.$emit('closeDialog');
    },
    getHallOrLocation(id) {
      let item = this.dhmeYardManagementAssemblyHalls.find(
        (item) => item.id === id
      );
      if (item) {
        return item;
      }
      return this.dhmeYardManagementStorageLocations.find(
        (item) => item.id === id
      );
    },
    async storeElementInLocation() {
      try {
        await this.$store.dispatch(
          'storeElementInLocation',
          this.selectedStorageBox.id
        );
        this.closeDialog();
      } catch (error) {}
    },
  },
};
</script>
